<template>
  <div class="container_con">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="销售计划单查询明细(未提取)" name="1">
          <plan ref="model1" />
        </el-tab-pane>
        <el-tab-pane label="销售计划单查询明细(已提取)" name="4">
          <plan2 ref="model4" />
        </el-tab-pane>
        <el-tab-pane label="销售出库单查询明细" name="2">
          <out ref="model2" />
        </el-tab-pane>
        <el-tab-pane label="销售退货单查询明细" name="3">
          <back ref="model3" />
        </el-tab-pane>
        <el-tab-pane label="商品汇总" name="5" v-if="have_auth('/summary')">
          <back2 ref="model5" v-if="have_auth('/summary')"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import plan from "../../components/sales/plan.vue";
import plan2 from "../../components/sales/plan2.vue";
import out from "../../components/sales/out.vue";
import back from "../../components/sales/back.vue";
import back2 from "../../components/sales/summary.vue";
export default {
  name: "salesSlect",
  components: {
    plan,
    plan2,
    out,
    back,
    back2
  },
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    this.activeName = sessionStorage.getItem("sales_active")
      ? sessionStorage.getItem("sales_active")
      : this.activeName;
    this.$nextTick(()=>{
        this.$refs['model'+this.activeName].getList()
    })
    this.old_active=this.activeName;
  },
  watch: {
    $route(to,form) {
        this.$refs['model'+this.old_active].save_filter();
    },
  },
  methods: {
    handleClick() {
      sessionStorage.setItem("sales_active", this.activeName);
      this.$refs['model'+this.activeName].getList();
      this.$refs['model'+this.old_active].save_filter();
      this.old_active=this.activeName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
