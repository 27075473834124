<template>
    <div class="container_con">
        <div class="new_search_box">
          <div style="margin-top:0px">
              <span class="mr_20">
                时间：
                <el-date-picker
                  v-model="queryParams.time"
                  type="daterange"  value-format="YYYY-MM-DD" 
                  placeholder="请选择时间"
                  range-separator="至" style="width: 300px;"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </span>
              <span class="mr_20">
                单据编号：
                <el-input
                  v-model="queryParams.sale_out_no"
                  placeholder="单据编号(多个,分割)"
                  style="width:200px"
                />
              </span>
              <span class="mr_20">
                客户名称：
                <el-input
                  v-model="queryParams.customer"
                  placeholder="客户名称(多个,分割)"
                  style="width:200px"
                />
              </span>
              
              <span class="mr_20">
                开票人：
                <el-select v-model="queryParams.admin_id" placeholder="输入开票人姓名搜索" clearable
                    style="width:220px" filterable>
                    <el-option :label="item.full_name+'('+item.department_name+')' + '-' + item.mnemonic_code" v-for="(item,index) in ywlist"
                        :key="index" :value="item.id"></el-option>
                </el-select>
              </span>
              
              <span class="mr_20">
                产品编号：
                <el-input
                  v-model="queryParams.product_no"
                  placeholder="产品编号(多个,分割)"
                  style="width:200px"
                />
              </span>
              <span class="mr_20">
                品名：
                <el-input
                  v-model="queryParams.product_name"
                  placeholder="品名(多个,分割)"
                  style="width:200px"
                />
              </span>
              <span class="mr_20">
                批号/生产日期：
                <el-input
                  v-model="queryParams.batch_number"
                  placeholder="批号/生产日期(多个,分割)"
                  style="width:200px"
                />
              </span>
              <span class="mr_20">
                备注：
                <el-input
                  v-model="queryParams.remark"
                  placeholder="备注"
                  style="width:200px"
                />
              </span>
            <el-button type="primary" @click="handleQuery()">搜索</el-button>
            <el-button @click="resetQuery()">重置</el-button>
          </div>
        </div>
        <div class="search">
            <div>
                <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/salesSlect/export')" :loading="is_export" @click="try_export">导出</el-button>
            </div>
        </div>
        <div style="margin-top:20px">
            <el-table :data="list" v-loading="loading" style="width: 100%" :row-class-name="tableRowClassName">
                <el-table-column prop="admin_name" label="业务员" width="100" />
                <el-table-column prop="department_name" label="部门" width="100" />
                <el-table-column prop="sale_out_date" label="日期" width="100" />
                <el-table-column label="客户名称">
                    <template v-slot="scope">
                        {{scope.row.merchant_name}}
                    </template>
                </el-table-column>
                <el-table-column label="产品编号">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.product_no}}
                    </template>
                </el-table-column>
                <el-table-column label="品名">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.product_name}}
                    </template>
                </el-table-column>
                <el-table-column label="单据编号">
                    <template v-slot="scope">
                        {{scope.row.sale_out_no}}
                    </template>
                </el-table-column>
                <el-table-column label="规格">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.spec_value_name}}
                    </template>
                </el-table-column>
                <el-table-column label="型号">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.model}}
                    </template>
                </el-table-column>
                <el-table-column label="单位">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.pack_unit_name}}
                    </template>
                </el-table-column>
                <el-table-column label="数量">
                    <template v-slot="scope">
                        {{scope.row.product_num}}
                    </template>
                </el-table-column>
                <el-table-column label="单价">
                    <template v-slot="scope">
                        {{scope.row.sale_price}}
                    </template>
                </el-table-column>
                <el-table-column label="金额">
                    <template v-slot="scope">
                        {{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column label="成本">
                    <template v-slot="scope">
                        {{(parseFloat(scope.row.product_price)*parseFloat(scope.row.product_num)).toFixed(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="毛利">
                    <template v-slot="scope">
                        {{scope.row.gross_profit_price}}
                    </template>
                </el-table-column>
                <el-table-column label="批号">
                    <template v-slot="scope">
                        {{scope.row.batch_number}}
                    </template>
                </el-table-column>
                <el-table-column label="有效期至">
                    <template v-slot="scope">
                        {{scope.row.valid_until || '长效期'}}
                    </template>
                </el-table-column>
                <el-table-column label="注册证名">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.register_cert_name}}
                    </template>
                </el-table-column>
                <el-table-column label="注册证号">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.register_cert_no}}
                    </template>
                </el-table-column>
                <el-table-column label="Code编码">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.coding}}
                    </template>
                </el-table-column>
                <el-table-column label="组件编码">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.module_coding}}
                    </template>
                </el-table-column>
                <el-table-column label="医保编码">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.medical_insurance_coding}}
                    </template>
                </el-table-column>
                <el-table-column label="自定义编码">
                    <template v-slot="scope">
                        {{scope.row.sale_plan_product.product.customize_coding}}
                    </template>
                </el-table-column>
                <!-- <el-table-column fixed="right" label="操作" width="50">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="handleDetail(scope.row)" v-if="have_auth('/purchaseSlect/view')">查看</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "validity",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    time:''
                },
                total:0,
                list:[],
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                jhdwlist:[],
                ywlist:[],
                is_export:false,
            };
        },
        created() {
            this.queryParams=window['salesSlect_out'] || {
              page: 1,
              size: 10,
              time:''
            }
            this.get_cglist()
        },
        methods: {
            save_filter(){
                window['salesSlect_out']=this.queryParams
            },
            tableRowClassName(data){
                if(data.row.is_return==1){
                    return 'red'
                }
                return ''
            },
            try_export(){
                if(this.is_export){return false;}
                this.is_export = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/SaleOutProduct/export", _data).then((res) => {
                    if (res.status == 200) {
                          var a = document.createElement('a');
                          let name=res.data.file.split('/')
                          var fileName = name[name.length-1];
                          a.download = fileName;
                          a.href = this.$http+'/'+res.data.file;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_export = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
            },
            resetQuery(){
                this.queryParams= {
                    page: 1,
                    size: 10,
                    time:''
                }
                this.getList()
            },
            
            get_cglist(keyword){
                this.$httpGet("/backend/sys.Admin/salesmanList", {size:10000000}).then((res) => {
                    if (res.status == 200) {
                        this.ywlist = res.data.data || [];
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            // 查看详情
            handleDetail(row){
                this.$router.push('/purchaseSlectDetail?id='+row.id)
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/SaleOutProduct/index", _data).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>
<style lang="scss" scoped>
    /deep/.el-table__row.red{color:red; background: #ffe7e7;}
</style>
